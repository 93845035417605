@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600;700;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('vars.css');
@import url('googleButton.css');

#root {
  height: 100%;
}

.large-table table > thead > tr > th:not(.ant-table-expand-icon-th) {
  min-width: 0px !important;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ql-color-accent1);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ql-color-accent1-t-lighten1);
}
.headerTop + div {
  /* use strict px number to make header+ aside works together */
  height: calc(100vh - var(--ql-header-height) - var(--ql-footer-height)) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.qlAsideLeft + div {
  overflow-y: auto;
}
/* Button */
.ant-btn-ghost {
  border-color: var(--ql-color-accent1) !important;
  border-width: 2px !important;
  color: var(--ql-color-accent1) !important;
}

/* Modal */
.ant-modal-content {
  border-radius: var(--modal-border-radius);
}
.ant-modal-header {
  border-top-left-radius: var(--modal-border-radius);
  border-top-right-radius: var(--modal-border-radius);
}
/* Table */
.ant-table-content {
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-gutter: auto;
}
.ant-table-container {
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-gutter: auto;
}
.ant-table-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;
}
.ant-select-single.ant-select-allow-clear.ant-select-show-arrow:has(.ant-select-clear):hover
  > .ant-select-arrow {
  display: none;
}

.img-crop-container {
  border-radius: var(--card-radius);
}
/* Select */
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  color: var(--input-disabled-color);
  background-color: var(--input-disabled-bg);
}
.ant-table-row:has(.tableCellCheckDetectingClass){
  background-color: var(--ql-layout-background);
}
.ant-table-filter-dropdown{
  border-radius: var(--ql-border-radius-lg);
}