:root {
  --ql-font-family-main: 'Open Sans', sans-serif;
  --ql-font-family-headings: 'Montserrat Alternates', sans-serif;

  /* Colors */

  /* accent */
  --color-accent: 79, 24, 125;
  --ql-color-accent1: rgb(var(--color-accent));
  --ql-color-accent1-t-lighten1: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten1)
  );
  --ql-color-accent1-t-lighten2: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten2)
  );
  --ql-color-accent1-t-lighten3: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten3)
  );
  --ql-color-accent1-t-lighten4: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten4)
  );
  --ql-color-accent1-t-lighten5: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten5)
  );
  --ql-color-accent1-t-lighten6: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten6)
  );

  --color-accent2: 104, 59, 143;
  --ql-color-accent2: rgb(var(--color-accent2));

  /* dark */
  --color-dark: 46, 32, 88;
  --ql-color-dark: rgba(var(--color-dark));
  --ql-color-dark-pure: rgb(51, 63, 63);
  --ql-color-dark-lighten1: rgba(var(--color-dark), 0.84);
  --ql-color-dark-lighten2: rgba(var(--color-dark), 0.64);
  --ql-color-dark-lighten3: rgba(var(--color-dark), 0.4);
  --ql-color-dark-lighten4: rgba(var(--color-dark), 0.24);
  --ql-color-dark-lighten5: rgba(var(--color-dark), 0.16);
  --ql-color-dark-lighten6: rgba(var(--color-dark), 0.08);

  /* blue */
  --color-blue: 60, 68, 242;
  --ql-color-blue: rgba(var(--color-blue));

  /* yellow */
  --color-yellow: 240, 205, 0;
  --ql-color-yellow: rgba(var(--color-yellow));

  /* grey */
  --color-grey: 240, 240, 240;
  --ql-color-grey: rgb(var(--color-grey));

  /* Neutral color */
  --color-white: 255, 255, 255;

  /* Typography */
  --ql-typography-text-color-primary-reverse: var(--ql-color-white);
  --ql-font-size-avatar: 150px;

  /* Outline */
  --ql-outline-color-danger: var(--ql-color-danger-t-lighten4);

  /* Layout */
  --ql-general-layout-px-xxs: 16px; /* mobile (portrait): 0 - 576px */

  --ql-layout-background: var(--ql-color-dark-t-lighten6);

  /* need to use header height to fix qonsoll layout */
  --ql-header-height: 56px;
  --ql-footer-height: 96px;

  /* Borders */
  --ql-dashed-border: 1px dashed var(--ql-color-dark-t-lighten5);
  /* Border radius */
  --ql-border-radius-small: 4px;
  --border-width-default: 1px;
  --border-style-default: solid;
  /* SPACINGS */
  --ql-spacing-xs: 4px;
  --ql-spacing-sm: 8px;
  --ql-spacing-md: 16px;
  --ql-spacing-lg: 32px;
  --ql-spacing-xl: 48px;
  --ql-spacing-xxl: 64px;

  /* Paddings */
  --ql-padding-xs: var(--ql-spacing-xs);
  --ql-padding-sm: var(--ql-spacing-sm);
  --ql-padding-md: var(--ql-spacing-md);
  --ql-padding-lg: var(--ql-spacing-lg);

  /* Margins */
  --ql-margin-xs: var(--ql-spacing-xs);
  --ql-margin-sm: var(--ql-spacing-sm);
  --ql-margin-md: var(--ql-spacing-md);
  --ql-margin-xl: var(--ql-spacing-xl);

  /* Aside */
  --ql-aside-background: var(--ql-color-white);
  --ql-aside-padding: 24px;

  /* Button */
  --btn-border-color: var(--ql-color-dark-t-lighten4);
  --btn-border-radius-base: var(--ql-border-radius-small);
  --btn-default-color: var(--ql-typography-text-color-secondary);
  --btn-default-hover-bg: transparent;
  --btn-default-hover-color: var(--ql-color-accent1);

  --btn-text-hover-bg: var(--ql-color-dark-t-lighten5);
  --btn-text-hover-color: var(--ql-color-dark);

  --btn-circle-box-shadow: 0px 2px 5px 0px var(--ql-color-dark-pure);
  /* Dropdown */
  --dropdown-item-default-bg-hover: var(--ql-color-dark-t-lighten5);
  --dropdown-item-danger-bg-hover: var(--ql-color-danger-t-lighten5);

  /* Menu */
  --ql-menu-group-title-font-weight: var(--ql-font-weight-semibold);
  --ql-menu-group-title-color: var(--ql-color-dark-t-lighten2);
  --ql-menu-group-title-padding: 8px 24px;
  --ql-menu-item-icon-margin-right: var(--ql-margin-md);

  /* Account */
  --ql-account-avatar-box-shadow-hover: inset 0 0 0 2px var(--ql-color-accent1);
  --ql-form-item-vertical-spacing-without-message: 24px;
  --ql-account-title-color: var(--ql-color-white);

  /* Card */
  --ql-card-background: var(--ql-color-accent1-t-lighten6);
  --ql-card-border-default: 1px solid transparent;
  --ql-card-border-selected: 1px solid var(--ql-color-accent1-t-lighten4);

  /* Grid */
  --ql-grid-gutter: var(--ql-grid-gutter-sm);
  --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-sm) * -1);

  /* Input */
  --input-bg: var(--ql-color-white);

  /* Custom specific vars */
  /* Table */
  --table-sticky-header-bg: var(--ql-color-grey);
  --table-body-max-height: 550px;
  --table-number-column-width: 100px;
  --table-actions-column-width: 150px;
  --table-status-column-width: 150px;
  --table-date-column-width: 200px;
  --table-phone-column-width: 250px;
  --table-points-column-width: 100px;
  --table-redeemed-column-width: 150px;
  --table-total-coupons-column-width: 200px;
  --table-reward-column-width: 200px;
  --table-email-column-width: 400px;
  --table-name-column-width: 400px;
  /* Scroll settings */
  --table-scroll-width-height: 4px;
  --table-scroll-top-button-height: 58px;
  --table-scroll-bg: var(--ql-layout-background);

  /* Modal */
  --modal-header-bg: var(--ql-color-accent1);
  --modal-border-radius: var(--ql-border-radius-lg);
  --modal-heading-color: var(--ql-typography-text-color-primary-reverse);
  --modal-footer-bg: var(--btn-default-bg);

  /* Box-shadow */
  --box-shadow-default: 0px 0px 0px rgba(var(--color-dark), 0.02),
  0px 0px 36px rgba(var(--color-dark), 0.3),
  0px 27px 27px rgba(var(--color-dark), 0.03),
  0px 7px 15px rgba(var(--color-dark), 0.04),
  0px 0px 0px rgba(var(--color-dark), 0.04);

  /* Popup */
  --popup-bg: var(--ql-color-white);
  --popup-border-radius: var(--ql-border-radius-20);
  --popup-box-shadow:  var(--box-shadow-default);

  /* Avatar */
  --avatar-color: var(--ql-typography-text-color-secondary);
}

/* Grid (breakpoints from @qonsoll/react-design) */
@media (min-width: 576px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-sm);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-sm) * -1);
  }
}
@media (min-width: 768px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-md);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-md) * -1);
  }
}
@media (min-width: 992px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-lg);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-lg) * -1);
  }
}
@media (min-width: 1200px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-xl);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-xl) * -1);
  }
}
@media (min-width: 1600px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-xxl);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-xxl) * -1);
  }
}
